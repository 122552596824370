
import { defineComponent, ref } from "vue";
/*import KTNewTargetModal from "@/components/modals/forms/NewTargetModal.vue";*/

export default defineComponent({
  name: "kt-widget-9",
  components: {
    /*KTNewTargetModal*/
  },
  props: {
    widgetClasses: String,
    hideBtnNewMember: Boolean
  },
  setup() {
    const checked = ref(false);
    const list = [
      {
        image: "media/avatars/150-11.jpg",
        name: "Ana Simmons",
        skills: "HTML, JS, ReactJS",
        phone: '+7(912)-033-32-55',
        companyName: "Intertico",
        paymentAmount: "#15756",
        group: 'lorem ipsum',
        companySkills: "Web, UI/UX Design",
        value: "50",
        email: 'alexrequire@mail.ru',
        color: "primary",
        register_date: '22.11.2021',
        paymentStatus: 'paid',
        source: 'youtube',
      },
      {
        image: "media/avatars/150-11.jpg",
        name: "Ana Piligrim",
        skills: "HTML, JS, ReactJS",
        phone: '+7(922)-055-77-55',
        companyName: "Intertico",
        paymentAmount: "#1654721",
        group: 'lorem ipsum',
        companySkills: "Web, UI/UX Design",
        value: "50",
        email: 'alexrequire@mail.ru',
        color: "primary",
        register_date: '11.11.2021',
        paymentStatus: 'paid',
        source: 'facebook',
      },
      {
        image: "media/avatars/150-3.jpg",
        name: "Jessie Clarcson",
        email: 'alexrequire@mail.ru',
        paymentAmount: "#17008",
        skills: "C#, ASP.NET, MS SQL",
        phone: '+7(912)-033-32-15',
        group: 'lorem ipsum',
        companyName: "Agoda",
        companySkills: "Houses & Hotels",
        value: "70",
        color: "danger",
        register_date: '15.05.2020',
        paymentStatus: 'not paid',
        source: 'instagram',
      },
      {
        image: "media/avatars/150-4.jpg",
        name: "Lebron Wayde",
        group: 'lorem ipsum',
        email: 'alexrequir2e@mail.ru',
        skills: "PHP, Laravel, VueJS",
        paymentAmount: "#12544",
        companyName: "RoadGee",
        phone: '+7(912)-033-32-35',
        companySkills: "Transportation",
        value: "60",
        color: "success",
        register_date: '24.07.2021',
        paymentStatus: 'not paid',
        source: 'instagram',
      },
      {
        image: "media/avatars/150-5.jpg",
        name: "Natali Goodwin",
        paymentAmount: "#15550",
        phone: '+7(912)-033-32-55',
        group: 'lorem ipsum',
        email: 'alexrdequir23e@mail.ru',
        skills: "Python, PostgreSQL, ReactJS",
        companyName: "The Hill",
        companySkills: "Insurance",
        value: "50",
        color: "warning",
        register_date: '18.09.2021',
        paymentStatus: 'paid',
        source: 'vk',
      },
      {
        image: "media/avatars/150-6.jpg",
        name: "Kevin Leonard",
        paymentAmount: "#15890",
        phone: '+7(912)-033-12-55',
        group: 'lorem ipsum',
        email: 'alexrdequir21e@mail.ru',
        skills: "HTML, JS, ReactJS",
        companyName: "RoadGee",
        companySkills: "Art Director",
        value: "90",
        color: "info",
        register_date: '04.12.2020',
        paymentStatus: 'not paid',
        source: 'ok',
      },
      {
        image: "media/avatars/150-6.jpg",
        name: "Kevin Leonard",
        paymentAmount: "#15890",
        phone: '+7(912)-033-12-55',
        group: 'lorem ipsum',
        email: 'alexrdequir21e@mail.ru',
        skills: "HTML, JS, ReactJS",
        companyName: "RoadGee",
        companySkills: "Art Director",
        value: "90",
        color: "info",
        register_date: '04.12.2020',
        paymentStatus: 'process',
        source: 'twitter',
      },
    ];

    return {
      list,
      checked,
    };
  },
});
